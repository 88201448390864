import React from "react";
import Particles from "react-tsparticles";

const NeuronParticles = () => (
  <Particles
    id="tsparticles"
    params={{
      particles: {
        number: {
          value: 80, // Number of particles to create a neuron network effect
          density: {
            enable: true,
            value_area: 1000, // Adjust the density to control network spread
          },
        },
        shape: {
          type: "circle", // Particles as circular dots representing neurons
        },
        opacity: {
          value: 0.5, // Base opacity of the particles
          random: false, // Uniform base opacity
          anim: {
            enable: true, // Enable opacity animation for blinking effect
            speed: 2, // Animation speed for blinking
            opacity_min: 0.1, // Minimum opacity during blink
            sync: false, // Each particle blinks independently
          },
        },
        size: {
          value: 5, // Base size of the particles
          random: true, // Randomize size for variation
          anim: {
            enable: false, // Disable size animation
          },
        },
        line_linked: {
          enable: true, // Enable lines connecting particles to mimic neuron synapses
          distance: 150, // Distance for linking particles
          color: "#ffffff", // Line color (white for contrast)
          opacity: 0.2, // Line opacity
          width: 1, // Line width
        },
        move: {
          enable: true, // Allow particles to move
          speed: 0.2, // Slow movement speed to simulate neuron activity
          direction: "none", // Movement in all directions
          random: true, // Random movement for a dynamic look
          straight: false, // Particles should not move in straight lines
          out_mode: "out", // Particles will move out of the canvas
        },
      },
      interactivity: {
        events: {
          onhover: {
            enable: false, // No reaction on mouse hover
          },
          onclick: {
            enable: false, // No reaction on mouse click
          },
        },
        modes: {
          push: {
            particles_nb: 0, // No new particles on click
          },
        },
      },
      retina_detect: true, // Enable retina detection for better visuals
    }}
  />
);

export default NeuronParticles;
