import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Decentralized solutions"
              description="Served as a tech lead, contributing to the design and development of a solution that enables unused hardware to be rented out as decentralized nodes, offering both storage and computing capabilities."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="Citopia Node"
              description="Designed a mixture of SaaS and NaaS service where an user affiliated to one provider can target specific service in another NaaS that are all affiliated together via VC membership to a central authority. The client authentication is done via did Authentication. "
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              isBlog={false}
              title="MAAS"
              description="
As a Full Stack Developer, System Designer and DevOps, I developed the solutions utilizing DID communication, verifiable credentials, and credential issuance. My work included designing the back-end for Citopia MaaS to handle route and trip planning using GTFS data, and creating a dynamic wrapper for OpenTripPlanner instances. I also optimized code, identified bottlenecks, assessed vulnerabilities, and orchestrated Kubernetes for performance testing with K6."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
