import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import upworkImg from "../../Assets/upwork.svg";
import Tilt from "react-parallax-tilt";
import { AiFillGithub, AiFillMail } from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Allow me to <span className="purple"> Introduce </span> myself
            </h1>
            <p className="home-about-body">
              I loved programming from my early years and have been following
              the passion stumbling from one project to another.
              <br />
              <br />I can code in
              <i>
                <b className="purple"> Javascript, Python, Go and Rust. </b>
              </i>
              <br />
              <br />
              My past work and expertise revolve around building &nbsp;
              <i>
                <b className="purple">
                  {" "}
                  Web Development, Blockchain and contract development, DevOps,
                  SecOps, Solution architect.{" "}
                </b>
              </i>
              <br />
              <br />I also apply my passion for learning new things and concept
              ranging from but not limited to{" "}
              <b className="purple">
                ZKP, SSI, System engineering and anything that tickles my fancy
                mostly maths.{" "}
              </b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>I am on</h1>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/sanjay-del"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/sanjay-pantha/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.upwork.com/freelancers/~0142fe88601c9a4226?mp_source=share"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <img
                    src={upworkImg}
                    alt="Upwork"
                    style={{ width: "24px", height: "24px" }}
                  />
                </a>
              </li>

              <li className="social-icons">
                <a
                  href="mailto:mail@sanjaypantha.com.np"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
